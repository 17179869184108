@keyframes grow {
  0% {
    r: 0;
    opacity: 1;
  }

  100% {
    r: 178.5;
    opacity: 0;
  }
}

.inner {
  animation-name: grow;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.outer {
  animation-name: grow;
  animation-duration: 2s;
  animation-delay: -1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}