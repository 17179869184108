:root {

  --sidebar-sub-container-background-light: rgba(175,129,228,.05);
  --sidebar-sub-container-background-dark: transparent;
  --sidebar-sub-container-background: var(--sidebar-sub-container-background-light);
}

.sidebar {
  grid-column: 2 / span 11;
  grid-row: var(--sidebar-row);
  height: fit-content;
  padding-top: 5px;
  padding-bottom: 20px; 
  position: sticky;
  top: var(--sidebar-sticky-position);
  background-color: var(--dashboard-section-background);
}

.sub-container {
  /* background-color: rgb(139, 252, 215); */
  /* background-color: rgba(131, 231, 242, 0.5); */
  border: 1px solid var(--border-color);
  border-radius: 15px;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 10px;
  padding-bottom: 8px;
  padding-top: 5px;
}

.input-container {
  text-align: center;
}

.input-label {
  margin-top: 3px;
  text-align: center;
  font-size: 14px;
}

.default-input {
  width: calc(100% - 40px);
  height: 30px;
}

.toggle-input {
  width: calc(100% - 85px);
  height: 25px;
  font-size: 14px;
}

.toggle-button {
  box-shadow: -1px -1px 1px #fff, 1px 1px 1px #babecc;
  margin: 5px;
  margin-bottom: 10px;
  margin-left: 5px;
  text-align: center;
  vertical-align: middle;
  font-family: 'Barlow', sans-serif;
  font-weight: 400;
  font-size:  16px;
  height: 20px;
  width: 30px;
  border: none;
  outline: none;
  background-color: transparent;
  padding: 0;
  padding-bottom: 2.4px;
  border-radius: 15px;
}

.toggle-img {
  width: 14px;
  height: 14px; 
  margin-top: 2px;
}

.hedging-label {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  background-color: var(--tooltip-background);
  border: .5px solid black;
  border-radius: 5px;
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 15px;
  height: 25px;
}

.arrow {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
}

.up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.hedging-arrow {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 15px;

}

.hedging-type-button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;

}

.hedging-amount {
  display: grid;
  align-items: center;
  justify-content: center;
  justify-items: center;
  grid-template-columns: 1fr;
  margin-top: 5px;
}