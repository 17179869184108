:root {
  --strategy-backtest-container-row-start : calc(var(--price-liquidity-container-row-start) + var(--price-liquidity-container-row-span) + 1);
  --strategy-backtest-container-row-span: 60;
  --strategy-backtest-title-span: 1;
  --strategy-backtest-chart-span: 15;
  --strategy-backtest-chart-span-column: 25;
  --backtest-indicators-row-span: 8;
  --backtest-indicators-column-span: 25;
  --strategy-dropdown-button-span: 4;
}

.strategy-backtest-container {
  grid-row: var(--strategy-backtest-container-row-start) / span var(--strategy-backtest-container-row-span);
  display: grid;
  grid-template-rows: repeat(50, 2%);
  grid-template-columns: repeat(25, 4%);
}

.strategy-backtest-title {
  grid-row: 1 / span var(--strategy-backtest-title-span);
  grid-column: 1 / span 25;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.strategy-backtest-title span, .strategy-backtest-title div {
  margin-right: 1em;
}

.strategy-backtest-chart {
  grid-row: 2 / span var(--strategy-backtest-chart-span);
  grid-column: 1 / span var(--strategy-backtest-chart-span-column);
}

.strategy-overview{
  grid-row: 2 / span var(--strategy-backtest-chart-span);
  grid-column: calc(var(--strategy-backtest-chart-span-column) + 2) / span 12;
}


.bar-mouseover {
  outline: 1px solid rgba(238, 175, 246);
  /* outline-offset: 0.5; */
  fill-opacity: 1;
  stroke-opacity: 0.5;
}

.backtest-indicators-container {
  grid-row: calc(var(--strategy-backtest-chart-span) + var(--strategy-backtest-title-span) + 4);
  grid-row: span calc(var(--strategy-backtest-container-row-span) - var(--strategy-backtest-chart-span) - var(--strategy-backtest-title-span) - 2);
  grid-column: 1 / span 25;
  display: grid;
  justify-content: flex-end;
  grid-template-rows: repeat(25, 4%);
  grid-template-columns: repeat(25, 4%);
}


.daily-prices {
  grid-row: 3 / 12;
  grid-column: 15 / span 12;
}

.backtest-indicators {
  grid-row: 1 / span var(--backtest-indicators-row-span);
  grid-column: 1 / span var(--backtest-indicators-column-span);
}

.backtest-indicators td, .backtest-indicators th {
  padding: 15px;
}

.backtest-indicators th {
  font-size: 12px;
  font-weight: 500;
}

.strategy-toggle-container {
  grid-row: calc(5 + var(--backtest-indicators-row-span)) / span 2;
  grid-column: 1;
}

.strategy-toggle {
  width: 240px;
}

.strategy-breakdown-container {
  grid-column: 1 / span 25;
  grid-row: calc(6 + var(--backtest-indicators-row-span)) / span 8;
  display: grid;
  grid-template-rows: repeat(10, 10%);
  grid-template-columns: repeat(25, 4%);
  grid-row-gap: 0.5em;
}

.strategy-breakdown-chart {
  grid-row: 3 / span 9;
}

.strategy-breakdown-chart-left {
  /* margin-right: 2%; */
  grid-column: 1 / span 12;
}

.strategy-breakdown-chart-right {
  grid-column: 14 / span 12;
}

.strategy-breakdown-total-return-title {
  grid-row: 2 / span 1;
  grid-column: 1 / span 12;
  display: flex;
  align-items: center;
}

.strategy-breakdown-total-return-perc-title {
  grid-row: 2 / span 1;
  grid-column: 14 / span 12;
  display: flex;
  align-items: center;
}

.strategy-dropdown {
  grid-row: 1 / span 1;
  grid-column: 14 / span 12;
  display: flex;
  align-items: baseline;
  justify-content: flex-end;
  padding-right: 15px;
  margin-top: 5px;
}


.strategy-dropdown + button {
  grid-row: 2 / span 1;
  grid-column: 14 / span 12;
  justify-self: flex-end;
  overflow: visible;
}

.strategy-dropdown + button + div {
  margin-top: 5px;
  grid-row: 3 / span var(--strategy-dropdown-button-span);
  grid-column: 14 / span 12;
  justify-self: flex-end;
  overflow: visible;
  display: grid;
  justify-items: start;
  align-items: flex-start;
  z-index: 99999999;
}

.strategy-dropdown + button + div button {
  width: 180px;
  height: 20px;
  margin-right: 5px;
  margin-bottom: 5px;
  margin-top: 0;
}

.strategy-backtest-title {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.liquidation-line {
}