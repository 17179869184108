.perp-link-container {
  grid-row: 2 / span 2;
  grid-column: 2 / span 11;
  position: sticky;
  top: 20px;
  display: grid;
  grid-template-columns: repeat(10, 10%);
  grid-row-gap: 10px;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 5px;
  overflow: visible;
}

.perp-link-container button {
  font-family: 'Sk-Modernist', sans-serif;
  background-color: #f38352;
  grid-column: 2 / span 8;
  height: 100%;
  border: 0.5px solid black;
  border-radius: 3px;
  font-weight: 500;
 
}

.perp-link-container button:hover {
  border: 1px solid #DAC5E3;
}

/* .perp-link-container button a {
  width: 100%;
  all:revert;
  height: 30px;
  grid-column: 1 / span 5;
} */

@media only screen and (max-width: 600px) {

  .perp-link-info {
    top: 5%;
    left: 5%;
    width: 80%;
  }
}

.perp-link-info {
  grid-column: 13 / span 22;
  grid-row: 1;
  position: sticky;
  top: 20px;
  margin-top: 15px;
  height: fit-content;
  background-color: var(--perp-blue);
  border: 1px solid black;
  border-radius: 8px;
  z-index: 999999999;
  display: grid;
  justify-content: center;
  padding: 10px;
}

.perp-link-info-close {
  position: absolute;
  right: 15px;
  top: 10px;
  width: 20px;
  height: 20px;
  display: flex;
  border-radius: 50% 50%;
  border: 0.5px solid black;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  background-color: var(--tooltip-background);
  padding: 0;
  margin: 0;
}

.perp-link-info-close:hover {
  border: 1px solid black;
}

.perp-url {
  height: 40px;
  width: 70px;
  border: 0.5px solid black;

  text-align: center;
  font-size: 14px;
  border-radius: 8px;
  padding: 10px;
  justify-content: flex-end;
  background-color: #f38352;
  
}

.perp-url a {
  text-decoration: none;
  color: black;
}

.perp-vals-container {
  display: grid;
  padding: 5px;
}

.perp-vals-name {
  text-align: center;
  padding: 10px;
}

.perp-vals-minmax {
  display: flex;
  /* justify-content: flex-start */
}

.perp-vals-minmax div {
 display: grid;
 justify-content: center;
 padding: 10px;

}

.perp-vals-minmax div div {
  border: 0.5px solid black;
}

.perp-vals-fyi {
  font-size: 14px;
}