@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@100;200;300;400;500;600;700;800&display=swap');


:root {
  /* colors */
  --darkest-grey: #4d5458;
  --medium-grey: #5f696f;
  --light-grey: #73818a;
  --white: #fff;
  --pink-icon: #E784BA;
  --window-height: 100px;
}

:root {
  /* LIGHT MODE */
  --light: rgba(231, 241, 250, 1);
  --semi-transparent-light: rgba(255,255,255,0.1);

  --radial-gradient-light: radial-gradient(at top right, rgba(246,216,250, 0.2), transparent),
  radial-gradient(rgba(236,227,251, 0.4), transparent),
  radial-gradient(at top left, rgba(231, 241, 250, 1), transparent),
  radial-gradient(at bottom right, rgba(216,237,250, 0.8), transparent),
  radial-gradient(at bottom left, rgba(250,246,216, 0.8), transparent);

  --linear-gradient-light: linear-gradient(315deg, rgba(255,225,232,0.33) 54%, rgba(255,225,232,0.41) 59%, rgba(255,241,245,0.89) 93%, rgba(255,241,245,1) 100%);
  --linear-gradient-light-2: linear-gradient(315deg, rgba(255,225,232,0.13) 54%, rgba(255,225,232,0.11) 59%, rgba(255,241,245,0.89) 93%, rgba(255,241,245,1) 100%),
    linear-gradient(135deg, rgba(139,210,250,0) 15%, rgba(182,221,248,0) 18%, rgba(255,241,245,0.04) 23%, rgba(229,236,247,0.37) 43%, rgba(185,228,252,0.37) 78%);


  --font-color-bold-light: var(--darkest-grey);
  --font-color-light: var(--medium-grey);

  --outer-glow-light:  3px 3px 5px #BABECC, -3px -3px 10px #f7f5fe;
  --inner-glow-light: inset 1px 1px 1px #babecc, inset -1px -1px 5px #f2f2f2;
  --outer-glow-intense-light: 3px 3px 5px #BABECC,  
  -1px -1px 2px #ffffff,
  -3px -3px 10px #ffffff;
  --border-color-light: #fff;

  --input-border-light: var(--inner-glow-light);
  --input-background-light: rgba(255,255,255, 1);
  --button-selected-background-light : var(--input-background-light);

  --button-border-light:  1px 1px 1px #BABECC, -1px -1px 1px #f7f5fe;
  --button-border-selected-light: inset 1px 1px 2px #babecc, inset -1px -1px 2px #f7f5fe;
  --button-border-hover-light: 1px 1px 1px #fff, -1px -1px 1px #babecc;
  --dashboard-section-background-light: hsla(0,0%,100%,.4);

}

:root {
  /* DARK MODE */ 
  --dark: rgba(25, 34, 43, 1);
  --darker: rgb(19, 28, 31);
  /* --dark: var(--darkest-grey); */
  --semi-transparent-dark: rgba(0, 0, 0, 0.08);
  --semi-transparent-medium-dark: rgba(39, 39, 41, 0.038);

  --radial-gradient-dark: radial-gradient(at top right, rgba(48, 42, 49, 0.2), transparent),
  radial-gradient(rgba(53, 50, 56, 0.4), transparent),
  radial-gradient(at top left, rgb(51, 54, 56), transparent),
  radial-gradient(at bottom right, rgba(44, 47, 49, 0.8), transparent),
  radial-gradient(at bottom left, rgba(56, 55, 49, 0.8), transparent);

  --linear-gradient-dark: linear-gradient(315deg, var(--dark) 54%, var(--dark) 59%, var(--dark) 93%, var(--dark) 100%);
  --linear-gradient-dark-2: linear-gradient(var(--dark) 0%, var(--dark) 100%);

  --font-color-bold-dark: var(--white);
  --font-color-dark: var(--white);

  --outer-glow-dark:  2px 2px 4px rgba(0, 0, 0, 0.4), -1px -1px 2px rgba(49, 54, 58, 0.4);
  --inner-glow-dark: inset 1px 1px 2px rgba(49, 54, 58, 0.4), inset -1px -1px 2px rgba(0, 0, 0, 0.4);
  --outer-glow-intense-dark: 3px 3px 5px rgba(0, 0, 0, 0.4),  
  -1px -1px 2px rgba(49, 54, 58, 0.4),
  -3px -3px 10px rgba(49, 54, 58, 0.4);
  --border-color-dark: var(--dark);

  --input-border-dark: inset 1px 1px 1px rgba(13, 14, 15, 0.4), inset -1px -1px 1px rgba(49, 54, 58, 0.4);
  --input-background-dark: var(--semi-transparent-dark);
  --button-selected-background-dark : var(--semi-transparent-medium-dark);
  --button-border-dark: 1px 1px 2px rgba(0, 0, 0, 0.4), -1px -1px 2px rgba(49, 54, 58, 0.4);
  --button-border-selected-dark: inset 1px 1px 1px rgba(255, 255, 255, 0.5), inset -1px -1px 1px rgba(255, 255, 255, 0.5);
  --button-border-hover-dark: 0.5px 0.5px 0.5px var(--light-grey), -0.5px -0.5px 0.5px #babecc;
  --dashboard-section-background-dark: transparent;
  

}

:root {

  /* Variables that can be changed using Theme Toggle component, default = lightmode */
  --background: var(--light);
  --background-radial: var(--radial-gradient-light);
  --background-linear: var(--linear-gradient-light);
  --background-linear-2: var(--linear-gradient-light-2);

  --semi-transparent-background: var(--semi-transparent-light);

  --foreground: var(--dark);
  --font-color-bold: var(--font-color-bold-light);
  --font-color: var(--font-color-light);

  --outer-glow: var(--outer-glow-light);
  --inner-glow: var(--inner-glow-light);
  --outer-glow-intense: var(--outer-glow-intense-light);
  --border-color: var(--border-color-light);
  --input-border: var(--input-border-light);
  --button-border: var(--button-border);
  --input-background: var(--input-background-light);
  --button-selected-background : var(--button-selected-background-light);
  --button-border-selected: var(--button-border-selected-light);
  --button-border-hover: var(--button-border-hover-light);

  --tooltip-background: var(--semi-transparent-background);
  --dashboard-section-background: var(--dashboard-section-background-light);
}

.App {
  background-color: var(--background);
  background-image: var(--background-radial);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: fit-content;
  min-width: var(--min-screen-width);
  min-height: 100vh;
  /* padding-bottom: 30px; */
}

.App {
  min-width: 1200px;
  width: 100vw;
  display: flex;
  height: fit-content;
  margin: 0;
  padding: 0;
  background: var(--background-linear);
  font-family: 'Barlow', sans-serif;
  color: var(--font-color);
}

.header-title {
  font-size: 18px;
  font-weight: 600;
  color: var(--font-color-bold);
}

.input {
    font-family: 'Barlow', sans-serif;
    grid-column: auto / span 3;
    grid-row: auto;
    justify-content: bottom;
    font-weight: 400;
    text-align: center;
    font-size: 16px;
    height: 30px;
    background-color: var(--input-background);
    box-shadow:  var(--input-border);
    border-radius: 14px;
    border: var(--border-color);
    text-align: center;
    margin: 5px;
    margin-left: 5px;
    color: var(--font-color);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

ul {
    list-style-type: none;
    padding: 0;
    list-style-position: inside;
    overflow: scroll;
}

svg {
  width: 100%;
  height: 100%;
  background: transparent;
}

.button {
  box-shadow: var(--button-border);
  text-align: center;
  vertical-align: middle;
  font-family: 'Barlow', sans-serif;
  font-weight: 400;
  border: none;
  outline: none;
  background-color: transparent;
  border-radius: 10px;
}

.button:hover {
  box-shadow: 0.5px 0.5px 0.5px rgb(181, 181, 181), -0.5px -0.5px 0.5px #babecc;
}

.button:active {
  box-shadow: inset -1px -1px 1px #fff, inset 1px 1px 1px #babecc;
}

.button.selected {
  box-shadow: var(--button-border-selected);
  background-color: var(--button-selected-background);
}

.button.selected:hover {
  box-shadow: var(--button-border-hover);
}

div.parent-container {
  min-width: var(--min-screen-width);
  margin: 15px;
  width: calc(100% - 0px);
}

div.dashboard-container {
  display: 'flex';
}


.button.list-item {
  background: rgba(255,255,255, 1);
  box-shadow:  inset 1px 1px 1px rgba(65,58,75, 0.1),
  inset 2px 2px 4px rgba(65,58,75, 0.1),
  inset -1px -1px 1px rgba(255,255,255, 1),
  inset -2px -2px 5px rgb(255,255,255),
  -1px -1px 1px rgba(241, 232, 252, 0.1),
  -3px -3px 3px rgba(241, 232, 252, 0.3),
   1px 1px 3px rgba(65,58,75, 0.01);
   border-radius: 10px;
   font-weight: 500;
}

.button.list-item:hover {
  background: rgba(212, 211, 211, 0.2);
}

.button.list-item:disabled {
  background: rgba(212, 211, 211, 1);
}

.outer-glow {
  background-color: var(--semi-transparent-background);
  border: 1px solid var(--border-color);
  border-radius: 15px;
  box-shadow:  var(--outer-glow);
}

.inner-glow {
  box-shadow: var(--input-border);
  border-radius: 8px;
  border: 1px solid var(--border-color);
  background-color: var(--semi-transparent-background);
}

.dashboard-section {
  grid-column: 14 / span 45;
  padding: 2%;
  padding-top: 10px;
  padding-top: 15px;
  background-color: var(--dashboard-section-background);
}

.title {
  font-weight: 500;
  display: flex;
  align-items: flex-end;
}

.sub-title {
  font-size: 14px;
  font-weight: 500;
}

.y-axis-label {
  font-size: 12px;
  fill: var(--font-color);
  stroke: none;
  font-weight: 400;
}



.current-price-line {
  stroke-width: 1px;
  stroke: rgb(255, 102, 102);
  stroke-dasharray: 2 2;
}

.current-price-circle {
  stroke-width: 1px;
  stroke-dasharray: none;
  fill: none;
}

.strategy-drag-control rect {
  fill: var(--light-grey);
  stroke: var(--medium-grey);
  stroke-width: 2px;
  stroke-opacity: 0.2;
}



g.chart-axis line, g.chart-axis-ticks {
  stroke: var(--font-color);
  fill:  var(--font-color);
  stroke-width: 0.4;
}

g.chart-axis-ticks {
  font-size: 8px;
  stroke: none;
  font-weight: 400;
}



.candle-green { 
  fill: #9bd8c8;
  stroke: #9bd8c8
}

.candle-red {
  fill: rgb(255, 102, 102);
  stroke: rgb(255, 102, 102);
}

.help-icon button {
  color: var(--pink-icon);
  font-weight: 600;
}

.arbitrum-error-message {
  grid-row: var(--strategy-backtest-container-row-start) / span 20;
  grid-column: 15 / span 50;
}


