.uniswap-link-container {
  grid-row: 2 / span 2;
  grid-column: 2 / span 11;
  position: sticky;
  top: 20px;
  display: grid;
  grid-template-columns: repeat(10, 10%);
  grid-row-gap: 10px;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 5px;
  overflow: visible;
}

.uniswap-link-container button {

  background-color: rgba(128, 232, 221, 0.7);
  grid-column: 2 / span 8;
  height: 100%;
  border: 0.5px solid var(--border-color);
  border-radius: 6px;
 
}