.container-perpetual {
  display: grid;
  justify-content: flex-start;
}

.td-perpetual {
  border: 1px solid black;
  border-radius: 5px;
  margin-left: 2%;
}

.td-text-perpetual {
  text-align: center;
}

.td-uniswap {
  text-align: center;
}

.td-text-uniswap {
  text-align: center;
}

.container-uniswap {
  box-shadow: var(--inner-glow);
  border-radius: 8px;
}

.container-uniswap table {
  border: 0.5px solid var(--font-color);
  border-collapse: collapse;
  margin: 2%;
}

.container-uniswap table tr, .container-uniswap table th, .container-uniswap table td {
  border: 0.5px solid var(--font-color);
}
