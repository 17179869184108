@font-face {
  font-family: "Sk-Modernist";
  src: local('Sk-Modernist'), url(../../fonts/Sk-Modernist-Regular.otf) format('opentype');
}

@font-face {
  font-family: "Sk-Modernist-Mono", monospace;
  src: local('Sk-Modernist-Mono'), url(../../fonts/Sk-Modernist-Mono.otf) format('opentype');
}

@font-face {
  font-family: "Sk-Modernist-Bold";
  src: local('Sk-Modernist-Bold'), url(../../fonts/Sk-Modernist-Bold.otf) format('opentype');
}


input[type="range"] {
  accent-color: var(--font-color);
  fill: #DAC5E3;
  flood-color: #DAC5E3;

}

:root {
  --window-height: 100px;
  --perp-purple: #CCBCFC;
  --perp-green: #5AFBC7;
  --perp-blue: #83E7F2;
  --perp-blue-2: #1EC4FA;
  

  /* --perp-purple2:rgb(164, 136, 251)C; */

  --perp-green-1: rgb(29, 191, 131);
  --perp-green-2: rgb(60, 235, 171);
  /* --perp-blue: rgb(0, 181, 216); */
  /* --perp-purple: rgb(194, 61, 235); */
  --black: rgb(0, 0, 0);
  --white: rgba(255, 255, 255);
  --perp-grey: rgb(89, 89, 89);
}

:root {
  /* LIGHT MODE */
  --perp-light: rgba(231, 241, 250, 1);
  --perp-perp-semi-transparent-light: rgba(255,255,255,0.1);

  --perp-perp-radial-gradient-light: radial-gradient(at top right, rgba(246,216,250, 0.2), transparent),
  radial-gradient(rgba(236,227,251, 0.4), transparent),
  radial-gradient(at top left, rgba(231, 241, 250, 1), transparent),
  radial-gradient(at bottom right, rgba(216,237,250, 0.8), transparent),
  radial-gradient(at bottom left, rgba(250,246,216, 0.8), transparent);

  --perp-linear-gradient-light: linear-gradient(315deg, rgba(255,225,232,0.33) 54%, rgba(255,225,232,0.41) 59%, rgba(255,241,245,0.89) 93%, rgba(255,241,245,1) 100%);
  --perp-linear-gradient-light-2: linear-gradient(315deg, rgba(255,225,232,0.13) 54%, rgba(255,225,232,0.11) 59%, rgba(255,241,245,0.89) 93%, rgba(255,241,245,1) 100%),
    linear-gradient(135deg, rgba(139,210,250,0) 15%, rgba(182,221,248,0) 18%, rgba(255,241,245,0.04) 23%, rgba(229,236,247,0.37) 43%, rgba(185,228,252,0.37) 78%);


  --perp-font-color-bold-light: var(--perp-darkest-grey);
  --perp-font-color-light: var(--perp-medium-grey);

  --perp-outer-glow-light:  3px 3px 5px #BABECC, -3px -3px 10px #f7f5fe;
  --perp-inner-glow-light: inset 1px 1px 1px #babecc, inset -1px -1px 5px #f2f2f2;
  --perp-outer-glow-intense-light: 3px 3px 5px #BABECC,  
  -1px -1px 2px #ffffff,
  -3px -3px 10px #ffffff;
  --perp-border-color-light: #fff;

  --perp-input-border-light: var(--perp-inner-glow-light);
  --perp-input-background-light: rgba(255,255,255, 1);
  --perp-button-selected-background-light : var(--perp-input-background-light);

  --perp-button-border-light:  1px 1px 2px #BABECC, -1px -1px 2px #f7f5fe;
  --perp-button-border-selected-light: inset 1px 1px 2px #DAC5E3, inset -1px -1px 2px #DAC5E3;
  --perp-button-border-hover-light: 1px 1px 1px #fff, -1px -1px 1px #babecc;

}

:root {
  /* DARK MODE */ 
  --perp-dark: rgba(25, 34, 43, 1);
  --perp-darker: rgb(19, 28, 31);
  /* --perp-dark: var(--perp-darkest-grey); */
  --perp-semi-transparent-dark: rgba(0, 0, 0, 0.08);
  --perp-semi-transparent-medium-dark: rgba(39, 39, 41, 0.038);

  --perp-radial-gradient-dark: radial-gradient(at top right, rgba(48, 42, 49, 0.2), transparent),
  radial-gradient(rgba(53, 50, 56, 0.4), transparent),
  radial-gradient(at top left, rgb(51, 54, 56), transparent),
  radial-gradient(at bottom right, rgba(44, 47, 49, 0.8), transparent),
  radial-gradient(at bottom left, rgba(56, 55, 49, 0.8), transparent);

  --perp-linear-gradient-dark: linear-gradient(315deg, var(--perp-dark) 54%, var(--perp-dark) 59%, var(--perp-dark) 93%, var(--perp-dark) 100%);
  --perp-linear-gradient-dark-2: linear-gradient(var(--perp-dark) 0%, var(--perp-dark) 100%);

  --perp-font-color-bold-dark: var(--perp-white);
  --perp-font-color-dark: var(--perp-white);

  --perp-outer-glow-dark:  2px 2px 4px rgba(0, 0, 0, 0.4), -1px -1px 2px rgba(49, 54, 58, 0.4);
  --perp-inner-glow-dark: inset 1px 1px 2px rgba(49, 54, 58, 0.4), inset -1px -1px 2px rgba(0, 0, 0, 0.4);
  --perp-outer-glow-intense-dark: 3px 3px 5px rgba(0, 0, 0, 0.4),  
  -1px -1px 2px rgba(49, 54, 58, 0.4),
  -3px -3px 10px rgba(49, 54, 58, 0.4);
  --perp-border-color-dark: var(--perp-dark);

  --perp-input-border-dark: inset 1px 1px 1px rgba(13, 14, 15, 0.4), inset -1px -1px 1px rgba(49, 54, 58, 0.4);
  --perp-input-background-dark: var(--perp-semi-transparent-dark);
  --perp-button-selected-background-dark : var(--perp-semi-transparent-medium-dark);
  --perp-button-border-dark: 1px 1px 2px rgba(0, 0, 0, 0.4), -1px -1px 2px rgba(49, 54, 58, 0.4);
  --perp-button-border-selected-dark: inset 1px 1px 2px rgba(0, 0, 0, 0.4), inset -1px -1px 2px rgba(49, 54, 58, 0.4);
  --perp-button-border-hover-dark: 0.5px 0.5px 0.5px var(--perp-light-grey), -0.5px -0.5px 0.5px #babecc;

}

:root {

  /* Variables that can be changed using Theme Toggle component, default = lightmode */
  --perp-background: var(--perp-light);
  --perp-background-radial: var(--perp-radial-gradient-light);
  --perp-background-linear: var(--perp-linear-gradient-light);
  --perp-background-linear-2: var(--perp-linear-gradient-light-2);

  --perp-semi-transparent-background: var(--perp-semi-transparent-light);

  --perp-foreground: var(--perp-dark);
  --perp-font-color-bold: var(--perp-font-color-bold-light);
  --perp-font-color: var(--perp-font-color-light);

  --perp-outer-glow: var(--perp-outer-glow-light);
  --perp-inner-glow: var(--perp-inner-glow-light);
  --perp-outer-glow-intense: var(--perp-outer-glow-intense-light);
  --perp-border-color: var(--perp-border-color-light);
  --perp-input-border: var(--perp-input-border-light);
  --perp-button-border: var(--perp-button-border);
  --perp-input-background: var(--perp-input-background-light);
  --perp-button-selected-background : var(--perp-button-selected-background-light);
  --perp-button-border-selected: var(--perp-button-border-selected-light);
  --perp-button-border-hover: var(--perp-button-border-hover-light);
}

.App {
  /* colors */
  --perp-darkest-grey: #4d5458;
  --perp-medium-grey: #5f696f;
  --perp-light-grey: #73818a;
  --perp-white: rgb(255,255,255);
  --perp-black: rgb(0,0,0);


  --perp-font-family-regular:  "Sk-Modernist";
  --perp-font-family-bold: "Sk-Modernist-Bold";
  --perp-font-family-mono: "Sk-Modernist-Mono";

}

.App {
  background-color: var(--perp-green);
  /* background: linear-gradient(176deg, #CCBCFC 2%, #CCBCFC 25%); */
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: fit-content;
  min-width: var(--perp-min-screen-width);
  min-height: 100vh;
  padding-bottom: 30px;
}

.App {
  min-width: 1200px;
  width: 100vw;
  display: flex;
  height: fit-content;
  margin: 0;
  padding: 0;
}

.App {
  font-family: 'Sk-Modernist', sans-serif;
  color: var(--perp-font-color);
}

.header-title {
  font-size: 18px;
  font-weight: 600;
  color: var(--perp-font-color-bold);
  font-family: 'Sk-Modernist-Bold';
}

.input {
    font-family: 'Sk-Modernist-Mono', sans-serif;
    grid-column: auto / span 3;
    grid-row: auto;
    justify-content: bottom;
    font-weight: 400;
    text-align: center;
    font-size: 16px;
    height: 30px;
    /* background-color: var(--perp-input-background); */
    /* box-shadow:  var(--perp-input-border); */
    border-radius: 8px;
    border: 0.5px solid var(--border-color);
    text-align: center;
    margin: 5px;
    margin-left: 5px;
    color: var(--perp-font-color);
    background-color: #8bfcd7;
}

.input::-webkit-outer-spin-button,
.input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input[type=number] {
  -moz-appearance: textfield;
}

ul {
    list-style-type: none;
    padding: 0;
    list-style-position: inside;
    overflow: scroll;
}

svg {
  width: 100%;
  height: 100%;
  background: transparent;
}

.button {
  /* box-shadow: -1px -1px 1px #fff, 1px 1px 1px #babecc; */
  text-align: center;
  vertical-align: middle;
  font-family: 'Sk-Modernist-Mono', sans-serif;
  font-weight: 400;
  border: 0.5px solid black;
  outline: none;
  background-color: var(--perp-green);
  border-radius: 8px;

}

.button:hover, .button.selected:hover {
  box-shadow: none;
  border: 1px solid #DAC5E3;
}

.button:active, .button.selected:active  {
  background-color: #8bfcd7;

}


.button.selected {
  /* box-shadow: var(--perp-button-border-selected); */
  border: 1px solid  black;
  background-color: rgba(252, 212, 212, 0.4);
  /* border: 0.9px solid black; */

}
/* 
.button.selected:active {
  background-color: transparent;
} */

.button.selected:hover {
  border: 0.9px solid black;
}

div.parent-container {
  min-width: var(--perp-min-screen-width);
  margin: 15px;
  width: calc(100% - 0px);
}

div.dashboard-container {
  display: 'flex';
}


button.list-item {
  background: rgba(255,255,255, 1);
  box-shadow:  inset 1px 1px 1px rgba(65,58,75, 0.1),
  inset 2px 2px 4px rgba(65,58,75, 0.1),
  inset -1px -1px 1px rgba(255,255,255, 1),
  inset -2px -2px 5px rgb(255,255,255),
  -1px -1px 1px rgba(241, 232, 252, 0.1),
  -3px -3px 3px rgba(241, 232, 252, 0.3),
   1px 1px 3px rgba(65,58,75, 0.01);
   border-radius: 10px;
   font-weight: 500;
}

button.list-item:hover {
  background: rgba(212, 211, 211, 0.2);
}

button.list-item:disabled {
  background: rgba(212, 211, 211, 1);
}

.outer-glow {
  /* background-color: var(--perp-purple); */
  border: 1.2px solid var(--black);
  border-radius: 5px;
  /* box-shadow:  var(--perp-outer-glow); */
}

.outer-glow-with-background {
  background-color: var(--perp-purple);
  border: 2px solid var(--black);
  border-radius: 5px;
  /* box-shadow:  var(--perp-outer-glow); */
}

.inner-glow {
  /* box-shadow: var(--perp-input-border); */
  border-radius: 8px;
  border: 0.5px solid var(--black);
  background-color: #7afbd2;
}

.inner-glow-with-background {
  /* box-shadow: var(--perp-input-border); */
  border-radius: 8px;
  border: 1px solid var(--black);
  background-color: #408873;
  /* background-color: rgba(24,199,255,0.1); */

}

.dashboard-section {
  grid-column: 14 / span 45;
  padding: 2%;
  padding-top: 10px;
  padding-top: 15px;
}

.title {
  font-weight: 500;
  display: flex;
  align-items: flex-end;
}

.sub-title {
  font-size: 14px;
  font-weight: 500;
}


.sub-container {
  background-color: var(--semi-transparent-background);
  border: 0.5px solid black;
  border-radius: 5px;
  /* box-shadow:  var(--inner-glow); */
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 10px;
  padding-bottom: 8px;
  padding-top: 5px;
}


.tab-so {
  grid-row: 12 / span 2;
  grid-column: 14 / span 20;
}

.tab-bt {
  grid-row: 93 / span 2;
  grid-column: 14 / span 20;
}

.tab {
  border: 1.2px solid black;
  border-right: none;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  background-color: var(--perp-blue);
	/* border-bottom: 50px solid var(--perp-green);
	border-right: 45px solid transparent;
	height: 0;
	width: 100% */
}

.tab2 {
  transform: skew(45deg);
  border: 1.2px solid black;
  grid-column: 15 / span 20;
  border-left: none;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: var(--perp-blue);
  display: flex;
  align-items: center;
  /* border-radius: 4px; */
  /* background-color: var(--perp-green); */

}

.tab-title {
  transform: skew(-45deg);
}

.help-icon button {
  color: var(--black);
}

