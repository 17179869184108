:root {
  --bar-fill: rgba(238, 175, 246, 0.5);
  --bar-stroke: rgba(238, 175, 246, 0.6);
}

.bar {
  stroke-width: 0.5px;
  fill: var(--bar-fill);
  stroke: var(--bar-stroke);
}

.bars {
  
}