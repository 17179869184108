.cli-container {
  padding: 5px;
  
}

.title {
  height: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
  font-size: 12px;
  color:'#5f696f';
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cli-value-container {
  display: flex;
  justify-content: space-around; 
  margin-top: 5px;
}

.cli-value {
  font-size: 20px; 
  padding: 5px;
  padding-top: 10px;
  padding-bottom: 10px; 
  width: 40%; 
  text-align: center; 
  border-radius: 8px;
  /* background-color: rgba(255, 255, 255, 0.2); */
}

.range-sizes-outer-container {
  /* background: transparent; */
  padding: 10px;
  display: grid;
  justify-content: center;
  align-items: center;
}

.range-sizes-inner-container {
  width: 90%; 
  display: grid;
  justify-content: center;
  align-items: center;
  padding: 5px;
}

.range-min-max-labels {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 5%;
  margin-top: 2%;
  color: #5f696f;
  font-size: 11px;
  font-weight: 300;
}

.range-min-max-label {
  width: 15%; 
  text-align: center;
  margin-right: 5px; 
}

.range-size-percent {
  margin-right: 5px;
  width: 15%;
  color: #5f696f;
  font-weight: 400;
  font-size: 12px; 
  text-align: center;
}

.token-ratio-container {
  display: grid;
  align-items: center;
  grid-template-columns: repeat(10, 1fr);
  padding: 8px;
  font-size: 12px; 
}

.token-ratio-base {
  grid-column: 7 / span 2;
}

.token-ratio-quote {
  grid-column: 9 / span 1;
}