:root {

  --strategy-container-row-span-default: 54;
  --strategy-chart-row-span-default: 13;

  --strategy-container-row-span-extended: 57;
  --strategy-chart-row-span-extended: 15;

  --strategy-container-row-start: 17;
  --strategy-chart-row-start: 2;

  --strategy-container-row-span: var(--strategy-container-row-span-default);
  --strategy-chart-row-span: var(--strategy-chart-row-span-default);

}

.strategy-overview-container {
  grid-row: var(--strategy-container-row-start) / span var(--strategy-container-row-span);
  display: grid;
  grid-template-rows: repeat(40, 2.5%);
  grid-template-columns: repeat(80, 1.25%);
  grid-row-gap: 0.5em;
  
}

.title {
  grid-row: 1 / span 1;
  grid-column: 1 / span 40;
  display: flex;
  align-items: center;
}

.strategy-chart {
  grid-row: var(--strategy-chart-row-start) / span var(--strategy-chart-row-span);
  grid-column: 1 / span 50;
  display: flex;
  
}

/* .strategy-chart svg{
  background-color: transparent;
} */

.strategy-indicators {
  grid-row: var(--strategy-chart-row-start) / span 12;
  grid-column: 52 / span 29;
}

.strategy-buttons {
  grid-row: calc( var(--strategy-chart-row-start) + var(--strategy-chart-row-span) + 1);
  grid-column: 31;
  width: 240px;
}


/* Position Breakdown by Token / Value */

.position-breakdown-title {
  grid-row: calc( var(--strategy-chart-row-start) + var(--strategy-chart-row-span) + 3) / span 1;
  grid-column: 2 / span 40;
  display: flex;
  align-items: flex-end;
}

.position-breakdown-container {
  grid-row: calc( var(--strategy-chart-row-start) + var(--strategy-chart-row-span) + 4) / span 9;
  grid-column: 1 / span 39;
  overflow: hidden;
  display: relative;
}

/* Impermanant Loss Chart */

.imp-loss-chart-dropdown {
  margin-top: 10px;
  text-align: right;
  grid-column: 62 / span 18;
  grid-row: calc( var(--strategy-chart-row-start) + var(--strategy-chart-row-span) + 2) / span 1;
}

.imp-loss-chart-dropdown + button {
  margin-top: 5px;
  padding-bottom: 5px;
  grid-row: calc( var(--strategy-chart-row-start) + var(--strategy-chart-row-span) + 3) / span 1;
  grid-column: 65 / span 13;
  overflow: visible;
}

.imp-loss-chart-dropdown + button + div {
  margin-top: 5px;
  grid-row: calc( var(--strategy-chart-row-start) + var(--strategy-chart-row-span) + 4) / span 4;
  grid-column: 65 / span 13;
  overflow: visible;
  display: grid;
  justify-items: start;
}

.imp-loss-chart-title {
  grid-row: calc( var(--strategy-chart-row-start) + var(--strategy-chart-row-span) + 3) / span 1;
  grid-column: 42 / span 39;
  display: flex;
  align-items: flex-end;
}

.imp-loss-chart-container {
  grid-row: calc( var(--strategy-chart-row-start) + var(--strategy-chart-row-span) + 4) / span 9;
  grid-column: 42 / span 39;
}


.token-ratio-chart {
  height: 100%;
  width: 100%;
}

.token-ratio-chart g text, .position-breakdown-token-ratio-container g text {
  fill: var(--font-color);
}

.token-ratio-visible {
  transform: translate(0%, 0);
  transition: 0.5s ease-in-out;
}

.token-ratio-hidden {
  transform: translate(-100%, 0%);
  transition: 0.5s ease-in-out;
}

.token-value-visible {
  transform: translate(0%, -100%);
  transition: 0.5s ease-in-out;
}

.token-value-hidden {
  transform: translate(100%, -100%);
  transition: 0.5s ease-in-out;
  
}

/* Position Breakdown Alternative View */

.position-breakdown-token-ratio-title {
  grid-row: calc( var(--strategy-chart-row-start) + var(--strategy-chart-row-span) + 3) / span 1;
  grid-column: 2 / span 40;
}

.position-breakdown-token-ratio-container {
  grid-row: calc( var(--strategy-chart-row-start) + var(--strategy-chart-row-span) + 4) / span 9;
  grid-column: 1 / span 39;
}

.position-breakdown-value-title {
  grid-row: calc( var(--strategy-chart-row-start) + var(--strategy-chart-row-span) + 3) / span 1;
  grid-column: 42 / span 39;
}

.position-breakdown-value-container {
  grid-row: calc( var(--strategy-chart-row-start) + var(--strategy-chart-row-span) + 4) / span 9;
  grid-column: 42 / span 39;
}
