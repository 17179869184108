.container {
  position: relative;
  display: inline-flex;
}

.button {
  background-color: var(--tooltip-background);
  color:var(--font-color);
  outline: none;
  border: 0.2px solid var(--border-color);
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  margin-left: 6px;
  margin-bottom: 6px;
  font-size: 9px;
  text-align: center;
  vertical-align: middle;
  padding: 0;

}

.container:hover .text {
  visibility: visible;
  box-shadow: var(--outer-glow-intense);
  border-radius: 8px;
  border: var(--border-color);
  padding: 12px;
  background-color: var(--background);
}

.text {
  position:absolute;
  top: 40px;
  left: -50px;
  width: 140px;
  font-size: 12px;
  padding: 1;
  text-align: center;
  transition-delay: 0.2s;
  visibility: hidden;
  z-index: 9999999;
  border: 1px solid var(--border-color)
}