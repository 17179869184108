
:root {

  --transition: 0.5s ease;

  --container-height: 30px;
  --container-width: 70px;

  --toggle-height: calc(var(--container-height) - (var(--container-height) * 0.1));
  --toggle-width: calc(var(--container-width) - (var(--container-width) * 0.1));

  --toggle-button-height: calc(var(--toggle-height) - (var(--toggle-height) * 0.3));
  --toggle-button-width: calc(var(--toggle-height) - (var(--toggle-height) * 0.3));

  --toggle-background-light: rgba(255,241,245,1);
  --toggle-background-dark: rgba(56, 53, 54);
  --toggle-background: var(var(--toggle-background-light))

}

.toggle-wrapper {
  width: var(--container-width);
  height: var(--container-height);
  display: block;
  margin: auto; 
}

.toggle {
  height: var(--toggle-height);
  width: var(--toggle-width);
  box-shadow: var(--button-border);
  background: var(--tooltip-background);
  border-radius: 40px;
  position: relative;
  transition: background var(--transition);
  cursor: pointer;
}

.toggle::before {
  content: "";
  display: block;
  border-radius: 50%;
  height: var(--toggle-button-height);
  width: var(--toggle-button-width);
  background: var(--toggle-background);
  box-shadow: var(--button-border);
  position: absolute;
  z-index: 2;
  transform: translate(0);
  transition: transform var(--transition);
  top: calc(var(--toggle-height) * 0.15);
  left: calc((var(--toggle-width) * 0.06));
  padding: 0;
  margin: 0;
}

.toggle.enabled::before {
  transform: translateX(calc( var(--toggle-width) -  var(--toggle-button-width) - (var(--toggle-width) * (2 * 0.06))));
}

.toggle input {
  opacity: 0;
  position: absolute;
  top: 0;
}

.toggle .icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  margin: 0 5px;

}

.toggle .icons svg {
  fill: var(--font-color);
  height: var(--toggle-button-height);
  width: var(--toggle-button-width);
  z-index: 0;
}

.hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
